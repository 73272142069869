import request from '@/utils/http.js'


// 人员管理列表
export function queryList(params) {
  return request({
    url: '/project/list/admin',
    method: 'get',
    params
  })
}

// 增加管理员
export function addAdmin(params) {
  return request({
    url: '/project/add/admin',
    method: 'post',
    data:params
  })
}

// 删除管理员
export function delAdmin(params) {
  return request({
    url: '/project/delete/admin',
    method: 'get',
    params
  })
}


// 获取功能权限列表
export function menuPerList(params) {
  return request({
    url: '/menu/list',
    method: 'get',
    params
  })
}

// 项目权限列表
export function projectPerList(params) {
  return request({
    url: '/menu/project/list',
    method: 'get',
    params
  })
}


// 设置项目权限
export function updateProjectPer(params) {
  return request({
    url: '/menu/project/set',
    method: 'post',
    data:params
  })
}


// 设置功能权限
export function menuOpenOrClose(params) {
  return request({
    url: '/menu/openOrClose',
    method: 'get',
    params
  })
}


