<template>
  <div class="app-container">
    <div class="main-body">
      <el-divider content-position="left"
        ><h1 style="font-size: 500; font-size: 16px">{{ projectName }}</h1></el-divider
      >
      <el-row>
        <search-table
          ref="searchTable"
          labelWidth="70px"
          :loading="listLoading"
          :searchFields="searchFields"
          :autoBtnList="autoBtnList"
          :colSize="colSize"
          :total="total"
          :tableData="dataList"
          :tableColumns="tableColumns"
          :baseColumns="baseColumns"
          :sortFields="sortFields"
          :checkboxColumn="true"
          :hasPagination="false"
          :showFooter="true"
          :showSetting="false"
          :showColumnSearch="false"
          :showSortable="true"
          :tableSummary="tableSummary"
          :proxyParams="proxyParams"
          :optionColumn="optionColumn"
          :selectTableSummary="selectTableSummary"
          @saveTableField="saveTableField"
          @saveSearchField="saveSearchField"
          @saveSortField="saveSortField"
          @getTableData="queryList"
          @setPer="setPer"
          @delPersonal="delPersonal"
        >
        </search-table>
      </el-row>
    </div>
    <el-dialog title="添加管理员" :visible.sync="dialogVisble" width="35%">
      <el-form :model="addForm" ref="addForm" :rules="rules">
        <el-form-item label="手机号:" prop="phone">
          <el-input v-model="addForm.phone" style="width: 70%" size="small" placeholder="请输入完整手机号"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button size="small" @click="dialogVisble = false">取 消</el-button>
        <el-button size="small" type="primary" @click="submitAddAdmin">提 交</el-button>
      </div>
    </el-dialog>

    <el-dialog title="权限设置" :visible.sync="perDialogVisble" width="45%">
      <div style="font-weight: bold; font-size: 14px; color: #1482f0">功能权限设置</div>
      <div style="position: relative">
        <div class="per-grid" v-for="item in menuList">
          <span style="float: left; font-size: 14px">{{ item.name }}</span>
          <span style="float: right">
            <el-switch
              v-model="item.isPer"
              @change="v => perChange(v, item)"
              :active-value="true"
              :inactive-value="false"
              size="small"
              active-color="rgba(64, 158, 255, 1)"
              inactive-color="rgba(220, 223, 230, 1)"
            >
            </el-switch>
          </span>
        </div>
      </div>
      <!-- <el-divider style="margin-bottom: 10px;"></el-divider> -->
      <div style="font-weight: bold; font-size: 14px; color: #1482f0">项目权限设置</div>
      <el-row style="margin-top: 15px" :key="index" v-for="(item, index) in bossProList">
        <el-col :span="12">
          <span style="float: left; font-size: 14px">{{ item.projectName }}</span>
          <span style="float: right">
            <el-switch
              size="small"
              @change="v => projectPerChange(v, item)"
              :active-value="1"
              :inactive-value="0"
              v-model="item.isPer"
              active-color="rgba(64, 158, 255, 1)"
              inactive-color="rgba(220, 223, 230, 1)"
            >
            </el-switch>
          </span>
        </el-col>
      </el-row>
    </el-dialog>
  </div>
</template>
<script>
import { queryList, addAdmin, delAdmin, projectPerList, updateProjectPer, menuOpenOrClose, menuPerList } from '@/api/personal'
import SearchTable from '@/components/LcSearchTable'
import config from '@/const/personal/index.js'
import { formatDate } from '@/utils/time'
import { columnSetting } from '@/api/common'
export default {
  components: {
    SearchTable
  },
  data: function () {
    const reg = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/
    const validatPhone = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('手机号不能为空'))
      } else {
        if (reg.test(value)) {
          callback()
        } else {
          return callback(new Error('请输入正确的手机号'))
        }
      }
    }
    return {
      total: 0,
      summary: {},
      bossProList: [
        [
          {
            projectId: '',
            projectName: '',
            isPer: '' //是否有权限 0-否 1-是
          }
        ]
      ],
      form: {
        phone: '',
        projectId: '',
        isPer: '' //是否有权限 0-否 1-是
      },
      dialogVisble: false,
      perDialogVisble: false,
      projectName: '',
      dataList: [],
      addForm: {
        projectId: '',
        phone: ''
      },
      rules: {
        phone: [{ required: true, message: '请输入手机号', tigger: 'blur' }, { validator: validatPhone }]
      },
      listLoading: false,
      queryParams: {
        projectId: '',
        name: '',
        phone: ''
      },
      projectId: '',
      // 查询条件
      baseColumn: [], //接口请求的表格配置
      baseColumns: [],
      tableColumns: [], //用户自定义的表格配置
      searchFields: [], //用户自定义的search配置
      tableSummary: {}, //后端返回的总合计数据
      selectTableData: [], //checkbox选中的表格数据
      colSize: 3, //用户设置展示列比例
      selectTableSummary: config.selectTableSummary, // 需要选中合计的字段
      sortFields: [], // 用户自定义的排序设置
      optionColumn: {
        title: '操作',
        width: 300,
        fixed: 'right',
        btnList: [
          {
            title: '删除',
            fn: 'delPersonal'
          },
          {
            title: '权限设置',
            fn: 'setPer'
          }
        ]
      },
      menuList: [],
      currentTenantId: '', // 当前设置权限的行租户id
      path: '/system/tenant/findByPage' // 更新字段名显示接口路径
    }
  },
  activated() {
    let projectId = this.$route.query.id
    this.projectId = projectId
    this.projectName = this.$route.query.name
    this.queryParams.projectId = projectId
    this.addForm.projectId = projectId
    this.queryList()
  },
  created() {
    this.searchFields = config.searchFields(this)
    this.baseColumns = config.tableFields(this)
    this.tableColumns = config.tableFields(this)
  },
  computed: {
    // 操作按钮
    autoBtnList() {
      return config.operateList(this)
    }
  },
  mounted() {},
  methods: {
    // 功能权限修改提交
    perChange(v, row) {
      let params = {
        isOpen: v,
        perms: row.perms,
        tenantId: this.currentTenantId
      }
      menuOpenOrClose(params).then(res => {
        if (res.code === 200) {
          this.$message.success('功能权限【' + row.name + '】更改成功')
        } else {
          this.$message.error('功能权限【' + row.name + '】更改失败')
        }
      })
    },
    // 项目权限修改
    projectPerChange(v, row) {
      let params = {
        isPer: v,
        phone: row.phone,
        projectId: row.projectId
      }
      updateProjectPer(params).then(res => {
        if (res.code === 200) {
          this.$message.success('项目【' + row.projectName + '】权限更改成功')
          this.getProjectListPer(row.phone)
        } else {
          this.$message.error('项目【' + row.projectName + '】权限更改失败')
        }
      })
    },
    // 提交
    submitAddAdmin() {
      this.$refs.addForm.validate(valid => {
        if (valid) {
          addAdmin(this.addForm).then(res => {
            if (res.code === 200) {
              this.$message.success('添加管理员成功')
              this.queryList()
              this.addForm.phone = ''
              this.dialogVisble = false
            } else {
              this.$message.error(res.msg)
            }
          })
        }
      })
    },
    // 添加管理员
    addHandler() {
      this.dialogVisble = true
    },
    // 批量添加
    batchAddHandler() {},
    // 删除
    delPersonal(row) {
      let params = {
        projectId: this.projectId,
        phone: row.phone
      }
      this.$confirm('是否确定删除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          delAdmin(params).then(res => {
            if (res.code === 200) {
              this.$message({
                type: 'success',
                message: '删除成功!'
              })
              this.queryList()
            } else {
              this.$message({
                type: 'error',
                message: res.msg
              })
            }
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    //权限设置
    async setPer(row) {
      this.menuList = []
      this.currentTenantId = row.id
      this.getMenuPerList(row.id)
      this.getProjectListPer(row.phone)
      this.perDialogVisble = true
    },
    // 获取功能权限
    async getMenuPerList(tenantId) {
      await menuPerList({ tenantId: tenantId }).then(res => {
        let menuList = res.data.menuList
        let perms = res.data.perms
        menuList.forEach(item => {
          let obj = { id: item.id, name: item.name, perms: item.perms, remark: item.remark, isPer: perms.includes(item.perms) }
          this.menuList.push(obj)
        })
      })
    },
    // 获取项目权限列表
    getProjectListPer(phone) {
      projectPerList({ phone: phone }).then(res => {
        this.bossProList = res.data.bossProList
      })
    },
    // 查询
    async queryList(params) {
      // if (params) {
      //   this.queryParams.current = params.current
      //   this.queryParams.size = params.size
      // } else {
      //   params = this.qeuryParams
      // }
      this.listLoading = true
      this.baseColumns = config.baseColumn(this)
      const res = await queryList(this.queryParams)
      if (res.code === 200) {
        this.dataList = res.data
        // this.total = res.data.total * 1
        // this.tableSummary = res.data.summary
        // this.baseColumns = res.data.tableSettingFields || config.baseColumn(this)
        this.baseColumns = config.baseColumn(this)
        // this.path = res.data.path || this.path
        // this.colSize = res.data.colSize
        // this.initConfig()
      }
      this.listLoading = false
    },
    initConfig() {
      this.tableColumns = this.baseColumns.filter(v => v.showField)
      this.searchFields = this.baseColumns.filter(v => v.isSearchShow)
      this.sortFields = this.tableColumns
        .filter(v => v.sortable)
        .reduce((prev, item) => {
          // if(item.sortList) prev = prev.concat(item.sortList)
          prev.push({
            title: `${item.title}升序`,
            field: `${item.field}-asc`
          })
          prev.push({
            title: `${item.title}降序`,
            field: `${item.field}-desc`
          })
          return prev
        }, [])
    },
    // 时间格式化
    dateFormat(data) {
      return formatDate(data)
    },
    //保存|修改表格头配置
    async saveTableField(fields) {
      this.tableColumns = fields
      try {
        const res = await columnSetting({
          path: this.path,
          // searchFields: this.searchFields,
          tableFields: fields
        })
        this.getTableData()
      } catch (error) {
        console.log(error)
      }
    },
    //保存|修改搜索字段配置
    async saveSearchField(fields, colSize) {
      // console.log(fields, colSize)
      this.colSize = colSize
      this.searchFields = fields
      try {
        const res = await columnSetting({
          path: this.path,
          searchFields: fields,
          // tableFields: this.tableColumns,
          colSize
        })
      } catch (error) {
        console.log(error)
      }
    },
    //保存|修改排序字段配置
    async saveSortField(fields) {
      this.sortFields = fields
    },
    // 处理数据
    proxyParams(params) {
      console.log(params)
    },

    //获取表格头配置
    async getTableField() {},
    //获取搜索字段配置
    async getSearchField() {}
  }
}
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.per-grid {
  float: left;
  padding: 10px;
  // border: 1px solid #f0f0f0;
  border-radius: 2;
  // box-shadow: 1px 0 0 0 #f0f0f0, 0 1px 0 0 #f0f0f0, 1px 1px 0 0 #f0f0f0,
  //   1px 0 0 0 #f0f0f0 inset, 0 1px 0 0 #f0f0f0 inset;
  transition: all 0.3s;
  width: 50% !important;
}
.input-cls {
  width: 260px;
  font-size: 14px;
  // feat: 搜索栏过多收起
  margin-right: 10px;
}
.icon-cls-green {
  color: #67c23a;
}
.dialog-row {
  margin-top: 10px;
}
</style>

<style rel="stylesheet/scss" lang="scss" scoped>
.validFieldClass .el-input__inner {
  border-color: #f56c6c;
}
.el-pagination {
  float: right;
}
.sortable-column-demo .vxe-header--row .vxe-header--column.sortable-ghost,
.sortable-column-demo .vxe-header--row .vxe-header--column.sortable-chosen {
  background-color: #dfecfb;
}
.vxe-table--footer .col-blue {
  background: #f2f5f0;
}
/*单号复制样式 开始*/
.copy-content {
  right: 0.1em;
  top: 0.1em;
  float: right !important;
  position: absolute !important;
}
.copy-content:hover {
  background-color: #1482f0;
  float: right !important;
  height: 1.8em;
  width: 1.8em;
}
.write-color {
  border-color: #f0f0f0;
}
/*单号复制样式 结束*/
.input-width-6 {
  width: 60% !important;
}
.input-width-7 {
  width: 70% !important;
}
.input-width-8 {
  width: 80% !important;
}
</style>
