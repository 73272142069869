
// 操作按钮配置项
const operateList = vm => {
  return [
    { name: '添加管理员', btnFn: vm.addHandler, plain: true, icon: 'el-icon-circle-plus' },
    // { name: '批量添加', btnFn: vm.batchAddHandler,  plain: true, icon: 'el-icon-edit' }
  ]
}

const baseColumn = vm => {
  return [
    {
      keyId: 1,
      title: '管理员名称',
      field: 'name',
      width: null,
      fieldType: null,
      searchType: 'input',
      searchValue: '',
      selectOptions: null,
      showField: true,
      enum: false,
      fieldEnumValueList: null,
      fieldOperateTag: null,
      sortable: false,
      multiField: false,
      fieldCanClick: false,
      filterable: false,
      textColor: null,
      fixed: null,
      renderType: null,
      copy: true
    },
    {
      keyId: 2,
      title: '手机号',
      field: 'phone',
      width: null,
      fieldType: null,
      searchType: 'input',
      searchValue: '',
      selectOptions: null,
      showField: true,
      enum: false,
      fieldEnumValueList: null,
      fieldOperateTag: null,
      sortable: false,
      multiField: false,
      fieldCanClick: false,
      filterable: false,
      textColor: null,
      fixed: null,
      renderType: null,
      copy: true
    },
    {
      keyId: 3,
      title: '最后上班时间',
      field: 'last_login_time',
      width: null,
      fieldType: null,
      searchType: 'input',
      searchValue: '',
      selectOptions: null,
      showField: true,
      enum: false,
      fieldEnumValueList: null,
      fieldOperateTag: null,
      sortable: false,
      multiField: false,
      fieldCanClick: false,
      filterable: false,
      textColor: null,
      fixed: null,
      renderType: null,
      copy: true
    }
  ]
}

// 搜索字段配置项
const searchFields = vm => {
  return [
    {
      keyId: 1,
      title: '管理员名称',
      field: 'name',
      width: 160,
      searchType: 'input',
      searchValue: '', //搜索绑定值
      sortable: true, //是否排序
      copy: true, //是否拥有复制功能
      columnType: null, // 筛选自定义
      filterable: true // 是否可筛选
    },
    // {
    //   keyId: 1,
    //   title: '电话',
    //   field: 'phone',
    //   width: 160,
    //   searchType: 'input',
    //   searchValue: '', //搜索绑定值
    //   sortable: true, //是否排序
    //   copy: true, //是否拥有复制功能
    //   columnType: null, // 筛选自定义
    //   filterable: true // 是否可筛选
    // },
  ]
}

// 表格列配置
const tableFields = vm => {
  return [
    {
      keyId: 1,
      title: '项目管理员名称',
      field: 'name',
      width: null,
      fieldType: null,
      searchType: 'input',
      searchValue: '',
      isSearchShow: true,
      selectOptions: null,
      showField: true,
      enum: false,
      fieldEnumValueList: null,
      fieldOperateTag: null,
      sortable: false,
      multiField: false,
      fieldCanClick: false,
      filterable: false,
      textColor: null,
      fixed: null,
      renderType: null,
      copy: true
    },
    {
      keyId: 2,
      title: '电话',
      field: 'phone',
      width: null,
      fieldType: null,
      searchType: 'input',
      searchValue: '',
      isSearchShow: false,
      selectOptions: null,
      showField: true,
      enum: false,
      fieldEnumValueList: null,
      fieldOperateTag: null,
      sortable: false,
      multiField: false,
      fieldCanClick: false,
      filterable: false,
      textColor: null,
      fixed: null,
      renderType: null,
      copy: true
    },
    {
      keyId: 3,
      title: '最后上班时间',
      field: 'last_login_time',
      width: 200,
      fieldType: null,
      searchType: 'input',
      searchValue: '',
      isSearchShow: false,
      selectOptions: null,
      showField: true,
      enum: false,
      fieldEnumValueList: null,
      fieldOperateTag: null,
      sortable: false,
      multiField: false,
      fieldCanClick: false,
      filterable: false,
      textColor: null,
      fixed: null,
      renderType: null,
      copy: true
    }
  ]
}

export default {
  operateList,
  searchFields,
  tableFields,
  baseColumn
}